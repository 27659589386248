/**
 * Email input
 * The validation is based on https://github.com/sindresorhus/email-regex
 */

import { Fragment, PureComponent } from 'react'

import styled from '@emotion/styled'
import emailRegex from 'email-regex'

import InputWithLabel from '@/stories/inputs/input-with-label'
import { lightTheme } from '@/theme'
import { ErrorIcon } from '@microcomponents/icons/new'

import { func, bool, string } from 'prop-types'

export class Email extends PureComponent {
  constructor(props) {
    super(props)
    // Formerly componentWillMount()
    this.validators = [
      // check for null if required
      (value) => {
        if (!props.required) return null
        if (!value)
          return (
            <Fragment>
              <ErrorIcon color={lightTheme.colors.danger} />
              Email is required
            </Fragment>
          )
      },
      // check for valid email entered
      (value) => {
        if (!value) return null
        const match = emailRegex({ exact: true }).test(value)
        return match ? null : (
          <Fragment>
            <ErrorIcon color={lightTheme.colors.danger} />
            Please enter a valid email
          </Fragment>
        )
      }
    ]
  }

  static contextTypes = {
    onChange: func
  }

  static propTypes = {
    id: string,
    name: string,
    required: bool
  }

  handleChange = (e) => {
    this.context.onChange(this.props.name, e.target.value, this.wrapper.inputContainer.isValid())
  }

  render() {
    return (
      <Input
        {...this.props}
        name={this.props.id || this.props.name}
        type="email"
        validators={this.validators}
        onChange={this.handleChange}
        ref={(wrapper) => {
          this.wrapper = wrapper
        }}
        eagerValidate
      />
    )
  }
}

const Input = styled(InputWithLabel)`
  height: 45px;
  margin: 0.5rem 0;
  width: 100%;

  &::placeholder {
    color: transparent;
  }

  .errorInput & {
    box-shadow: 0 1.5px 1px -1px ${({ theme }) => theme.colors.danger};
  }
`
