import withPage from '@/hocs/page'
import LoginComponent from '@/stories/login'

export function getStaticProps() {
  // We can use  these cookies for redirections of the login page if needed later
  // const { cookies } = req
  // const isLoggedIn = cookies.eaze_token && cookies.eaze_userId && cookies.eaze_coordinates

  // any additional logic here if needed, such as redirections or other checks can be passed in the return similar to pages/index
  return {
    props: {
      // other props if needed
    }
  }
}

function LoginPage() {
  return <LoginComponent />
}

export default withPage(LoginPage)
